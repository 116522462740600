<template>
  <div style="display: contents">
    <v-btn
      color="color3"
      x-small
      text
      @click.stop="openMe"
    >
      Manage Pending Items
    </v-btn>
    <v-navigation-drawer
      right
      v-model="drawer"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="valid"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color2 color2Text--text" tile dense class="top">
          <v-toolbar-title>Manage Pending Items</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab
            small
            @click.stop="cancel"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="go"
                :disabled="!valid"
                :loading="loading"
                block
                class="py-9"
              >
                {{ selectedAction ? selectedAction.btn : 'Save'}}{{ selectedItems && selectedItems.length > 0 ? ` (${selectedItems.length})` : null }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-container class="pt-13">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              label="Search"
              append-icon="fas fa-search"
              single-line
              hide-details
              v-model="search"
              clearable
              color="color3"
              solo-inverted
            >
            </v-text-field>

          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="pendingItems"
              hide-default-footer
              show-select
              item-key="id"
              :loading="loading"
              :search="search"
              :items-per-page="-1"
              checkbox-color="success"
              v-model="selectedItems"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row dense v-if="selectedItems.length">
            <v-col cols="12" class="text-h6 text-left">
              Select an action
            </v-col>
            <v-col cols="12">
              <v-select
                :items="[
                  // { label: 'Mark As Paid', btn: 'Mark'},
                  { label: 'Send Invoice', btn: 'Send'},
                  { label: 'Withdraw', btn: 'Withdraw'}
                ]"
                item-text="label"
                return-object
                label="Action"
                color="color3"
                item-color="color3"
                clearable
                v-model="selectedAction"
              ></v-select>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: ['pendingItems', 'tournament'],
  data () {
    return {
      drawer: false,
      loading: false,
      search: null,
      selectedItems: [],
      selectedAction: null
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'Division',
          align: 'left',
          sortable: true,
          value: 'division',
          show: true
        },
        {
          text: 'Team',
          align: 'left',
          sortable: true,
          value: 'teamName',
          show: true
        },
        { text: 'Players', value: 'playerNames', show: false },
        {
          text: 'Date Registered',
          align: 'left',
          sortable: true,
          value: 'unix',
          show: false
        },
        {
          text: '$ Pending',
          align: 'left',
          sortable: true,
          value: 'pending',
          show: false
        }
      ].filter(f => f.show)
    },
    valid () {
      return this.selectedAction && this.selectedItems.length > 0
    },
    dto () {
      return {
        ids: this.selectedItems.map(m => m.shoppingBagItemId),
        action: this.selectedAction.btn,
        eventId: this.tournament.id,
        username: this.tournament.organization.username
      }
    }
  },
  methods: {
    test () {
      console.log(this.selectedItems)
    },
    go () {
      this.loading = true
      this.$http.post('cart/managePending', this.dto)
        .then(r => {
          if (this.dto.action === 'Send') {
            this.$emit('email-sent')
          } else {
            this.$emit('updated')
          }
          this.cancel()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    openMe () {
      this.drawer = true
    },
    cancel () {
      this.selectedItems = []
      this.selectedAction = null
      this.drawer = false
    }
  }
}
</script>
